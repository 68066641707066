<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
      fill="currentColor"/>
    <path
      d="M13.5 4.75C13.5 4.96094 13.4062 5.14844 13.2656 5.28906L7.26562 11.2891C7.125 11.4297 6.9375 11.5 6.75 11.5C6.53906 11.5 6.35156 11.4297 6.21094 11.2891L3.21094 8.28906C3.07031 8.14844 3 7.96094 3 7.75C3 7.32812 3.32812 7 3.75 7C3.9375 7 4.125 7.09375 4.26562 7.23438L6.75 9.69531L12.2109 4.23438C12.3516 4.09375 12.5391 4 12.75 4C13.1484 4 13.5 4.32812 13.5 4.75Z"
      fill="#242424"/>
  </svg>
</template>
