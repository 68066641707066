import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-body-m text-filter-group-title mb-4" }
const _hoisted_2 = { class: "d-flex flex-column checkbox-list" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaCheckbox = _resolveComponent("BsaCheckbox")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsToDisplay, (label) => {
          return (_openBlock(), _createBlock(_component_BsaCheckbox, _mergeProps({ key: label }, _ctx.$attrs, {
            label: label,
            value: label,
            class: "checkbox-item"
          }), null, 16, ["label", "value"]))
        }), 128)),
        (_ctx.moreItemsThanShowCount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_v_btn, {
                "prepend-icon": "$plusIcon",
                class: "text-body-s",
                onClick: _ctx.toggleShow
              }, {
                default: _withCtx(() => [
                  (_ctx.showMore)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('showLess')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('showMore')), 1))
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}