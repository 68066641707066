<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.375 14.375C16.1289 14.375 15.9102 14.293 15.7461 14.1289L12 10.3828L8.22656 14.1289C7.89844 14.4844 7.32422 14.4844 6.99609 14.1289C6.64062 13.8008 6.64062 13.2266 6.99609 12.8984L11.3711 8.52344C11.6992 8.16797 12.2734 8.16797 12.6016 8.52344L16.9766 12.8984C17.332 13.2266 17.332 13.8008 16.9766 14.1289C16.8125 14.293 16.5938 14.375 16.375 14.375Z"
      fill="currentColor"/>
  </svg>
</template>
