import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaTextField = _resolveComponent("BsaTextField")!
  const _component_BsaLinkButton = _resolveComponent("BsaLinkButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BsaButton = _resolveComponent("BsaButton")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("form", {
    class: "h-100 d-flex align-center justify-center",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.signIn && _ctx.signIn(...args)), ["prevent"]))
  }, [
    _createVNode(_component_v_col, { md: "4" }, {
      default: _withCtx(() => [
        _createVNode(_component_BsaTextField, {
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          label: _ctx.$t('fields.email'),
          type: "email",
          required: "",
          class: "mb-12"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_BsaTextField, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          label: _ctx.$t('fields.password'),
          type: "password",
          required: "",
          class: "mb-4"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_router_link, { to: "/reset-password" }, {
          default: _withCtx(() => [
            _createVNode(_component_BsaLinkButton, { class: "text-body-s mb-12" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('password.forgot?')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_BsaButton, {
          block: "",
          type: "submit",
          "button-type": "bsa-primary",
          icon: "$longArrowRight"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('login.login')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}