import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaValidationListItem = _resolveComponent("BsaValidationListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsaValidationListItem, {
      class: "mr-4",
      validated: _ctx.hasAtLeast8Characters
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('validate.atLeast8characters')), 1)
      ]),
      _: 1
    }, 8, ["validated"]),
    _createVNode(_component_BsaValidationListItem, {
      class: "mr-4",
      validated: _ctx.hasNumbersAndLetters
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('validate.numbersAndLetters')), 1)
      ]),
      _: 1
    }, 8, ["validated"]),
    _createVNode(_component_BsaValidationListItem, { validated: _ctx.hasASpecialCharacter }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('validate.aSpecialCharacter')), 1)
      ]),
      _: 1
    }, 8, ["validated"])
  ]))
}