<template>
  <v-btn
    variant="plain"
    class="bsa-link-btn text-body-s text-link px-0">
    <slot />
  </v-btn>
</template>

<style>
.bsa-link-btn {
  text-decoration: underline;
}
</style>
