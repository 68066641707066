// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-pro/css/all.css';
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

import { customDarkTheme, customLightTheme } from '@/plugins/custom-themes';
import { aliases, custom } from '../iconsets/custom';
import { aliases as faAliases, fa } from 'vuetify/iconsets/fa';

export default createVuetify({
  // https://next.vuetifyjs.com/en/features/theme/
  theme: {
    defaultTheme: 'dark',
    themes: {
      light: customLightTheme,
      dark: customDarkTheme
    }
  },
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      ...faAliases,
    },
    sets: {
      custom,
      fa,
    }
  }
})
