
import { defineComponent, PropType } from 'vue';

import BsaCheckbox from './BsaCheckbox.vue';

export default defineComponent({
  name: 'BsaCheckboxGroup',
  components: {
    BsaCheckbox
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<string[]>,
      required: true,
    },
    showCount: {
      type: Number,
      default: 3,
    }
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    moreItemsThanShowCount(): boolean {
      return this.items.length > this.showCount;
    },
    itemsToDisplay(): string[] {
      if (this.showMore) {
        return this.items;
      }
      return this.items.slice(0, this.showCount);
    }
  },
  methods: {
    toggleShow() {
      this.showMore = !this.showMore;
    }
  }
});
