import { h } from 'vue';

import AngleDown from '@/components/icons/AngleDown.vue';
import AngleUp from '@/components/icons/AngleUp.vue';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import DarkCheckboxOff from '@/components/icons/DarkCheckboxOff.vue';
import DarkCheckboxOn from '@/components/icons/DarkCheckboxOn.vue';
import DarkCog from '@/components/icons/DarkCog.vue';
import DarkSignOut from '@/components/icons/DarkSignOut.vue';
import LightCheckboxOff from '@/components/icons/LightCheckboxOff.vue';
import LightCheckboxOn from '@/components/icons/LightCheckboxOn.vue';
import LightCog from '@/components/icons/LightCog.vue';
import LightSignOut from '@/components/icons/LightSignOut.vue';
import LongArrowLeft from '@/components/icons/LongArrowLeft.vue'
import LongArrowRight from '@/components/icons/LongArrowRight.vue';
import MapMarker from '@/components/icons/MapMarker.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import SaveIcon from '@/components/icons/SaveIcon.vue';
import TimesIcon from '@/components/icons/TimesIcon.vue';

const aliases = {
  angleDown: AngleDown,
  angleUp: AngleUp,
  checkIcon: CheckIcon,
  darkCheckboxOff: DarkCheckboxOff,
  darkCheckboxOn: DarkCheckboxOn,
  darkCog: DarkCog,
  darkSignOut: DarkSignOut,
  lightCheckboxOff: LightCheckboxOff,
  lightCheckboxOn: LightCheckboxOn,
  lightCog: LightCog,
  lightSignOut: LightSignOut,
  longArrowLeft: LongArrowLeft,
  longArrowRight: LongArrowRight,
  mapMarker: MapMarker,
  plusIcon: PlusIcon,
  saveIcon: SaveIcon,
  timesIcon: TimesIcon
} ;

const custom = {
  component: (props: any) => h(props),
};

export { aliases, custom };
