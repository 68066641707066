
import { defineComponent } from 'vue';
import { mapActions, mapWritableState } from 'pinia';

import BsaButton from './BsaButton.vue';
import BsaTextField from './BsaTextField.vue';

import { useCandidateStore } from '@/store/candidate';
import { useSnackbarStore } from '@/store/snackbar';

export default defineComponent({
  name: 'TheSettingsForm',
  components: {
    BsaButton,
    BsaTextField
  },
  computed: {
    ...mapWritableState(useCandidateStore, ['settingsFirstname', 'settingsLastname', 'email', 'password']),
    ...mapWritableState(useSnackbarStore, ['active', 'message'])
  },
  methods: {
    ...mapActions(useCandidateStore, ['updateCandidate', 'resetSettings']),
    resetForm() {
      this.resetSettings();
    },
    updateMe() {
      this.updateCandidate()
      .then(() => {
        this.active = true;
        this.message = this.$t('update.success');
      })
      .catch((error: any) => {
        this.active = true;
        if (error.response.status === 409) {
          const errors = error.response.data.errors.password;
          if (errors.some((e: string) => e.includes('is too short'))) {
            this.message = this.$t('password.mustBe8Characters');
          } else if (errors.some((e: string) => e.includes('is invalid'))) {
            this.message = this.$t('password.mustContainNumberAlpha');
          }
        } else {
          this.message = this.$t('update.error');
        }
      });
    }
  }
})
