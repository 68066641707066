import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-display-m-bold text-primary mb-12" }
const _hoisted_2 = { class: "d-flex flex-wrap justify-center justify-md-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaTextField = _resolveComponent("BsaTextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BsaButton = _resolveComponent("BsaButton")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    onSubmit: _withModifiers(_ctx.updateMe, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "9",
            lg: "6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('settings')), 1),
              _createVNode(_component_v_row, { class: "mb-6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "pb-0 pb-md-3 mb-8 mb-md-0",
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BsaTextField, {
                        modelValue: _ctx.settingsFirstname,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingsFirstname) = $event)),
                        label: _ctx.$t('fields.firstname')
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "pt-0 pt-md-3",
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BsaTextField, {
                        modelValue: _ctx.settingsLastname,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settingsLastname) = $event)),
                        label: _ctx.$t('fields.lastname')
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "mb-6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BsaTextField, {
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                        readonly: "",
                        label: _ctx.$t('fields.emailAddress'),
                        type: "email"
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "mb-12 mb-md-16" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BsaTextField, {
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                        label: _ctx.$t('fields.password'),
                        type: "password"
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_BsaButton, {
                  class: "mb-4 mb-md-0 mr-0 mr-md-4",
                  "button-type": "bsa-secondary",
                  icon: "$timesIcon",
                  onClick: _ctx.resetForm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('discardChanges')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BsaButton, {
                  type: "submit",
                  "button-type": "bsa-primary",
                  icon: "$saveIcon"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('saveChanges')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}