
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BsaCardAvatar',
  props: {
    imageSrc: {
      type: String,
      required: true,
    }
  }
})
