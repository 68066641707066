
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import BsaCard from '@/components/BsaCard.vue';
import TheDashboardFiltersPanel from '@/components/TheDashboardFiltersPanel.vue';
import TheDashboardHeader from '@/components/TheDashboardHeader.vue';
import TheSearchBar from '@/components/TheSearchBar.vue';

import { useEnumsStore } from '@/store/enums';
import { useFiltersStore } from '@/store/filters';

const TABS = [
  {
    title: 'tab.jobs',
    value: 'jobs',
  },
  {
    title: 'tab.startups',
    value: 'startups',
  },
];
export default defineComponent({
  name: 'DashboardView',
  components: {
    BsaCard,
    TheDashboardFiltersPanel,
    TheDashboardHeader,
    TheSearchBar,
  },
  data() {
    return {
      tab: 'jobs',
      tabItems: TABS,
    };
  },
  computed: {
    ...mapState(useFiltersStore, ['startupCards', 'jobCards']),
  },
  mounted() {
    this.fetchEnums();
    this.fetchMeetings();
    this.fetchStartups();
  },
  methods: {
    ...mapActions(useEnumsStore, ['fetchEnums']),
    ...mapActions(useFiltersStore, [
      'contactStartup',
      'fetchMeetings',
      'fetchStartups',
    ]),
  },
});
