import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheBsaLogo = _resolveComponent("TheBsaLogo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TheLoginForm = _resolveComponent("TheLoginForm")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "h-screen" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        "no-gutters": "",
        class: "flex-column h-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { class: "d-flex flex-grow-0 justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_TheBsaLogo, { class: "mt-6" })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { class: "flex-grow-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_TheLoginForm)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}