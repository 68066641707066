
import { defineComponent } from 'vue';

import BsaButton from '@/components/BsaButton.vue';

export default defineComponent({
  name: 'TheConfirmCard',
  components: {
    BsaButton
  },
  props: {
    startup: {
      type: String,
      required: true,
    }
  },
  emits: ['confirmContact'],
  data() {
    return {
      dialog: false,
    }
  },
})
