import { defineStore, storeToRefs } from 'pinia';

import MeetingApi from '@/backend/MeetingApi';
import StartupApi from '@/backend/StartupApi';
import { useCandidateStore } from './candidate';

const localeOptions: any = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const useFiltersStore = defineStore('filters', {
  state: () => {
    return {
      selectedLocations: [] as string[],
      tags: [] as string[],
      search: '',
      startups: [] as any,
      meetings: [] as any,
      selectedAmountsRaised: [] as string[],
      selectedJobs: [] as string[],
    };
  },
  actions: {
    async fetchMeetings() {
      this.meetings = (await MeetingApi.index()).data;
    },
    async fetchStartups() {
      this.startups = (
        await StartupApi.index(
          this.selectedLocations,
          this.tags,
          this.search,
          this.selectedAmountsRaised,
          this.selectedJobs
        )
      ).data.records;
    },
    async contactStartup(name: string) {
      await MeetingApi.create(name);
      await this.fetchMeetings();
      await this.fetchStartups();
    },
  },
  getters: {
    jobCards: (state) => {
      const cards: any[] = [];
      const { dreamJobs } = storeToRefs(useCandidateStore());
      state.startups.map((startup: any) => {
        const profiles = startup['Profiles searched'] || [];
        profiles.map((profile: any) => {
          if (dreamJobs.value.includes(profile)) {
            cards.push({
              title: startup['Name'],
              location: startup['Location'],
              rightChip: profile,
              description: startup['Business activity'],
              website: startup['Website'],
              mailSent: state.meetings.some(
                (m: any) => m['Name'] === startup['Name']
              ),
              publishedAt: new Date(
                startup['Creation date']
              ).toLocaleDateString('en-US', localeOptions),
              logoUrl: startup.Logo && startup.Logo[0]?.url,
              contactLinkedin: startup['Founder LinkedIn'],
              contact: startup['Founder name'],
            });
          }
        });
      });
      return cards;
    },
    startupCards: (state) =>
      state.startups.map((startup: any) => {
        const chips = startup['Profiles searched'] || [];
        if (startup['Company tags']) {
          chips.push(...startup['Company tags']);
        }
        return {
          title: startup['Name'],
          location: startup['Location'],
          chips,
          description: startup['Business activity'],
          website: startup['Website'],
          mailSent: state.meetings.some(
            (m: any) => m['Name'] === startup['Name']
          ),
          publishedAt: new Date(startup['Creation date']).toLocaleDateString(
            'en-US',
            localeOptions
          ),
          logoUrl: startup.Logo && startup.Logo[0]?.url,
          contactLinkedin: startup['Founder LinkedIn'],
          contact: startup['Founder name'],
        };
      }),
  },
});
