
import { defineComponent } from 'vue';

import TheBsaLogo from './TheBsaLogo.vue';
import TheHeaderProfile from '@/components/TheHeaderProfile.vue'

export default defineComponent({
  name: 'TheDashboardHeader',
  components: {
    TheBsaLogo,
    TheHeaderProfile
  }
})
