import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!

  return (_openBlock(), _createBlock(_component_v_avatar, {
    size: "56",
    rounded: "lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_img, { src: _ctx.imageSrc }, null, 8, ["src"])
    ]),
    _: 1
  }))
}