import axios from './axios';

export default {
  index: (
    locations?: string[],
    tags?: string[],
    search?: string,
    fundsRaised?: string[],
    profilesSearched?: string[]
  ) =>
    axios.get('/startups', {
      params: {
        locations,
        tags,
        search,
        stage: fundsRaised,
        profiles_searched: profilesSearched,
      },
    }),
};
