
import { defineComponent } from 'vue';
import { mapActions, mapWritableState } from 'pinia';

import BsaButton from './BsaButton.vue';
import BsaTextField from './BsaTextField.vue';

import { useLoginStore } from '@/store/login';
import { useSnackbarStore } from '@/store/snackbar';

export default defineComponent({
  name: 'TheLoginForm',
  components: {
    BsaButton,
    BsaTextField
  },
  computed: {
    ...mapWritableState(useLoginStore, ['email']),
    ...mapWritableState(useSnackbarStore, ['active', 'message'])
  },
  methods: {
    ...mapActions(useLoginStore, ['resetPassword']),
    askReset() {
      this.resetPassword()
          .then(() => {
            this.active = true;
            this.message = this.$t('resetPassword.sent');
            this.$router.push('/login');
          })
          .catch(() => {
            this.active = true;
            this.message = this.$t('resetPassword.errorMessage');
          });
    }
  }
})
