import EnumsApi from '@/backend/EnumsApi';
import { defineStore } from 'pinia';

export const useEnumsStore = defineStore('enums', {
  state: (): Record<string, string[]> => ({
    locations: [],
    company_tags: [],
    amounts_raised: [],
    profiles_searched: [],
  }),
  actions: {
    async fetchEnums(): Promise<any> {
      const response = await EnumsApi.index();
      this.locations = response.data.locations;
      this.company_tags = response.data.company_tags;
      this.amounts_raised = response.data.amounts_raised;
      this.profiles_searched = response.data.profiles_searched;
    },
  },
});
