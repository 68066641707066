<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6445 9.82812C13.6445 9.69141 13.5625 9.52734 13.4258 9.44531L12.1406 8.73438C12.1953 8.40625 12.25 8.07812 12.25 7.75C12.25 7.44922 12.1953 7.12109 12.1406 6.79297L13.4258 6.08203C13.5625 6 13.6445 5.83594 13.6445 5.67188C13.6445 5.28906 12.4961 2.96484 11.8945 2.96484C11.8125 2.96484 11.7305 2.99219 11.6758 3.04688L10.3906 3.78516C9.89844 3.34766 9.35156 3.04688 8.75 2.82812V1.35156C8.75 1.13281 8.58594 0.941406 8.39453 0.914062C7.92969 0.804688 7.46484 0.75 7 0.75C6.50781 0.75 6.04297 0.804688 5.57812 0.914062C5.38672 0.96875 5.25 1.13281 5.25 1.35156V2.82812C4.62109 3.04688 4.07422 3.34766 3.58203 3.78516L2.29688 3.04688C2.24219 2.99219 2.16016 2.96484 2.07812 2.96484C1.53125 2.96484 0.328125 5.20703 0.328125 5.67188C0.328125 5.83594 0.410156 6 0.546875 6.08203L1.83203 6.79297C1.77734 7.12109 1.75 7.42188 1.75 7.75C1.75 8.07812 1.77734 8.40625 1.83203 8.73438L0.546875 9.44531C0.410156 9.52734 0.328125 9.69141 0.328125 9.82812C0.328125 10.2383 1.47656 12.5625 2.07812 12.5625C2.16016 12.5625 2.24219 12.5352 2.29688 12.4805L3.58203 11.7422C4.07422 12.1523 4.62109 12.4805 5.25 12.6992V14.1758C5.25 14.3945 5.38672 14.5586 5.57812 14.6133C6.04297 14.6953 6.50781 14.75 6.97266 14.75C7.41016 14.75 7.90234 14.7227 8.36719 14.6133C8.58594 14.5586 8.75 14.3945 8.75 14.1758V12.6992C9.35156 12.4805 9.89844 12.1523 10.3906 11.7422L11.6758 12.4805C11.7305 12.5352 11.8125 12.5625 11.8945 12.5625C12.4414 12.5352 13.6445 10.3203 13.6445 9.82812ZM7 9.9375C5.76953 9.9375 4.8125 8.98047 4.8125 7.75C4.8125 6.54688 5.76953 5.5625 7 5.5625C8.20312 5.5625 9.1875 6.54688 9.1875 7.75C9.1875 8.98047 8.20312 9.9375 7 9.9375Z"
      fill="white"/>
  </svg>
</template>

