import UserApi from '@/backend/UserApi';

export const requireAuth = async (to: { path: string }) => {
  if (['/', '/settings'].includes(to.path)) {
    try {
      await UserApi.me();
    } catch(err: any) {
      return '/login';
    }
  }
}
