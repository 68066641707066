
import { defineComponent } from 'vue';

import { useTheme } from 'vuetify/lib/framework.mjs';

export default defineComponent({
  computed: {
    themeColor(): string {
      const theme = useTheme()
      return theme.global.current.value.dark ? 'dark' : 'light'
    },
    falseIcon(): string {
      return '$' + this.themeColor + 'CheckboxOff';
    },
    trueIcon(): string {
      return '$' + this.themeColor + 'CheckboxOn';
    }
  }
})
