
import { defineComponent, PropType } from 'vue';

import BsaCardAvatar from '@/components/BsaCardAvatar.vue';
import BsaChip from '@/components/BsaChip.vue';

import TheConfirmCard from './TheConfirmCard.vue';

export default defineComponent({
  name: 'BsaJobCard',
  components: {
    BsaCardAvatar,
    BsaChip,
    TheConfirmCard,
  },
  props: {
    to: {
      type: String,
      default: null,
    },
    contact: {
      type: String,
      default: null,
    },
    contactTo: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    chips: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    location: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    publishedAt: {
      type: String,
      default: 'unknown',
    },
    actionDone: {
      type: Boolean,
      default: false,
    },
    doneActionText: {
      type: String,
      default: 'Done',
    },
    logoUrl: {
      type: String,
      default: '',
    },
    rightChip: {
      type: String,
      default: '',
    },
  },
  emits: ['cardClick'],
});
