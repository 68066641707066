import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheSettingsHeader = _resolveComponent("TheSettingsHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TheSettingsForm = _resolveComponent("TheSettingsForm")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pt-4 pt-md-14" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "mb-12 mb-md-16" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "10",
            "offset-md": "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TheSettingsHeader)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "10",
            "offset-md": "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TheSettingsForm)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}