import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    modelValue: _ctx.search,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      _ctx.fetchStartups
    ],
    placeholder: _ctx.searchBarText,
    "single-line": "",
    class: "bsa-search-bar",
    variant: "underlined",
    "hide-details": "auto"
  }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]))
}