
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BsaButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    buttonType: {
      validator(type: string) {
        return ['bsa-primary', 'bsa-secondary'].includes(type);
      },
      required: true
    }
  }
})
