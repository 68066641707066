export default {
  "card": {
    "getInTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])}
  },
  "changeTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme"])},
  "contactStartup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contact ", _interpolate(_named("startup")), "?"])},
  "discardChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
  "fields": {
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error happened during login"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "password": {
    "createContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password that contains:"])},
    "createButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create password"])},
    "forgot?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "mustBe8Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long."])},
    "mustContainNumberAlpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain a number and a special character."])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send password reset"])}
  },
  "resetPassword": {
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error happened during reset request."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email to reset your password was succesfully sent."])}
  },
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "searchBar": {
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a company, a job title, a place"])}
  },
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "tab": {
    "startups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startups"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])}
  },
  "update": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during update"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update success"])}
  },
  "validate": {
    "atLeast8characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters"])},
    "numbersAndLetters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers and letters"])},
    "aSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A special character"])}
  },
  "welcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the club!"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer highly curated off-market job opportunities while respecting your privacy."])}
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
}