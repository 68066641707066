<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.5C5.49219 1.5 3.5 3.51562 3.5 6C3.5 7.82812 4.10938 8.34375 7.53125 13.2656C7.74219 13.5938 8.23438 13.5938 8.44531 13.2656C11.8672 8.32031 12.5 7.82812 12.5 6C12.5 3.51562 10.4844 1.5 8 1.5ZM8 7.875C6.94531 7.875 6.125 7.03125 6.125 6C6.125 4.94531 6.94531 4.125 8 4.125C9.03125 4.125 9.875 4.94531 9.875 6C9.875 7.03125 9.03125 7.875 8 7.875Z"
      fill="currentColor"/>
  </svg>
</template>
