<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15.25C11.7539 15.25 11.5352 15.168 11.3711 15.0039L6.99609 10.6289C6.64062 10.3008 6.64062 9.72656 6.99609 9.39844C7.32422 9.04297 7.89844 9.04297 8.22656 9.39844L12 13.1445L15.7461 9.39844C16.0742 9.04297 16.6484 9.04297 16.9766 9.39844C17.332 9.72656 17.332 10.3008 16.9766 10.6289L12.6016 15.0039C12.4375 15.168 12.2188 15.25 12 15.25Z"
      fill="currentColor"/>
  </svg>
</template>
