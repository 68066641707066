
import { defineComponent } from 'vue';

import TheBsaLogo from '@/components/TheBsaLogo.vue';
import TheWelcomeForm from '@/components/TheWelcomeForm.vue'
import TheWelcomeParagraph from '@/components/TheWelcomeParagraph.vue'

export default defineComponent({
  name: 'WelcomeView',
  components: {
    TheBsaLogo,
    TheWelcomeForm,
    TheWelcomeParagraph,
  },
  mounted() {
    if (!this.$route.query.token) {
      // Redirect to landing page
      window.location.href = 'https://app.bsa.club';
    }
  }
})
