import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, _mergeProps(_ctx.$attrs, {
    class: "bsa-field",
    variant: "underlined",
    "hide-details": "auto",
    label: _ctx.label
  }), null, 16, ["label"]))
}