<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7266 12.3789L15.2266 15.8789C14.8984 16.2344 14.3242 16.2344 13.9961 15.8789C13.6406 15.5508 13.6406 14.9766 13.9961 14.6484L15.9922 12.625H5.875C5.38281 12.625 5 12.2422 5 11.75C5 11.2305 5.38281 10.875 5.875 10.875H15.9922L13.9961 8.87891C13.6406 8.55078 13.6406 7.97656 13.9961 7.64844C14.3242 7.29297 14.8984 7.29297 15.2266 7.64844L18.7266 11.1484C19.082 11.4766 19.082 12.0508 18.7266 12.3789Z"
      fill="currentColor"/>
  </svg>
</template>
