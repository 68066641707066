
import { defineComponent } from 'vue';
import { mapActions, mapWritableState } from 'pinia';

import BsaButton from './BsaButton.vue';
import BsaLinkButton from './BsaLinkButton.vue';
import BsaTextField from './BsaTextField.vue';

import { useLoginStore } from '@/store/login';
import { useSnackbarStore } from '@/store/snackbar';

export default defineComponent({
  name: 'TheLoginForm',
  components: {
    BsaButton,
    BsaLinkButton,
    BsaTextField
  },
  computed: {
    ...mapWritableState(useLoginStore, ['email', 'password']),
    ...mapWritableState(useSnackbarStore, ['active', 'message'])
  },
  methods: {
    ...mapActions(useLoginStore, ['postLogin']),
    signIn() {
      this.postLogin()
          .then(() => { this.$router.push('/') })
          .catch((error) => {
            if (error.response.status === 401) {
              this.active = true;
              this.message = this.$t('login.errorMessage');
            }
          });
    }
  }
})
