<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.2666 12.3789L8.7666 15.8789C9.09473 16.2344 9.66895 16.2344 9.99707 15.8789C10.3525 15.5508 10.3525 14.9766 9.99707 14.6484L8.00098 12.625H18.1182C18.6104 12.625 18.9932 12.2422 18.9932 11.75C18.9932 11.2305 18.6104 10.875 18.1182 10.875H8.00098L9.99707 8.87891C10.3525 8.55078 10.3525 7.97656 9.99707 7.64844C9.66895 7.29297 9.09473 7.29297 8.7666 7.64844L5.2666 11.1484C4.91113 11.4766 4.91113 12.0508 5.2666 12.3789Z"
      fill="currentColor"/>
  </svg>
</template>
