const cream = '#F7F0D4';
const black = '#242424';
const white = '#FFFFFF';
const success = '#47B48D';
const error = '#D03E3E';
const pinkish = 'E0C0F0';
const darkGrey100 = '#999999';
const darkGrey300 = '#6E6E6E';
const darkGrey500 = '#404040';
const darkGrey900 = '#1F1F1F';
const lightGrey100 = '#FAFAFA';
const lightGrey300 = '#D8D8D8';
const lightGrey500 = '#C2C2C2';
const lightGrey700 = '#696969';
const lightGrey900 = '#242424';

export const customDarkTheme: any = {
  dark: true,
  colors: {
    background: '#050505',
    primary: cream,
    secondary: white,
    success: success,
    error: error,
    link: white,
    'autocomplete-bg': darkGrey500,
    'autocomplete-border': darkGrey500,
    'autocomplete-item-bg__hover': darkGrey300,
    'autocomplete-item-text__hover': white,
    'autocomplete-placeholder': darkGrey100,
    'autocomplete-selected-bg__hover': darkGrey500,
    'autocomplete-selected-border': darkGrey500,
    'autocomplete-selected-icon__hover': white,
    'autocomplete-selected-icon': darkGrey100,
    'autocomplete-selected-text__hover': darkGrey100,
    'autocomplete-selected-text': '#C0C0C0',
    'card-background': '#2B2B2B',
    'card-border': '#333333',
    'card-subtitle': darkGrey100,
    'card-title': white,
    'constraint-text': darkGrey300,
    'constraint-text__valid': white,
    'checkbox-border__hover': darkGrey100,
    'checkbox-border': darkGrey500,
    'dashboard-section-title': white,
    'filter-group-title': darkGrey100,
    'homepage-text': white,
    'primary-button__default': cream,
    'primary-button__disabled': darkGrey300,
    'primary-button__hover': white,
    'primary-button-text__default': black,
    'primary-button-text__disable': darkGrey500,
    'primary-button-text__hover': black,
    'profile-background': darkGrey500,
    'profile-btn-background__hover': white,
    'profile-btn-background': cream,
    'profile-btn-icon': black,
    'profile-item-background__hover': darkGrey300,
    'profile-text': white,
    'search-field-text': darkGrey300,
    'secondary-button__default': black,
    'secondary-button__disabled': black,
    'secondary-button__hover': black,
    'secondary-button-text__default': cream,
    'secondary-button-text__disable': darkGrey300,
    'secondary-button-text__hover': white,
    'slider-border': darkGrey300,
    'slider-thumb': darkGrey300,
    'slider-track': darkGrey500,
    'tag-background': pinkish,
    'tag-text': darkGrey900,
    'text-field__placeholder': darkGrey300,
    'text-field__text': white,
    'text-field__underline__active': cream,
    'text-field__underline__error': error,
  }
}

export const customLightTheme: any = {
  dark: false,
  colors: {
    background: '#FAFAFA',
    primary: black,
    secondary: lightGrey700,
    success: success,
    error: error,
    link: lightGrey900,
    'autocomplete-bg': white,
    'autocomplete-border': lightGrey300,
    'autocomplete-item-bg__hover': cream,
    'autocomplete-item-text__hover': black,
    'autocomplete-placeholder': lightGrey700,
    'autocomplete-selected-bg__hover': lightGrey500,
    'autocomplete-selected-border': lightGrey700,
    'autocomplete-selected-icon__hover': lightGrey900,
    'autocomplete-selected-icon': lightGrey700,
    'autocomplete-selected-text__hover': lightGrey900,
    'autocomplete-selected-text': lightGrey700,
    'card-background': white,
    'card-border': white,
    'card-subtitle': lightGrey700,
    'card-title': lightGrey900,
    'constraint-text': lightGrey700,
    'constraint-text__valid': lightGrey900,
    'checkbox-border__hover': lightGrey700,
    'checkbox-border': lightGrey300,
    'dashboard-section-title': black,
    'filter-group-title': lightGrey700,
    'homepage-text': black,
    'primary-button__default': black,
    'primary-button__disabled': lightGrey700,
    'primary-button__hover': black,
    'primary-button-text__default': white,
    'primary-button-text__disable': lightGrey500,
    'primary-button-text__hover': cream,
    'profile-background': white,
    'profile-btn-background__hover': cream,
    'profile-btn-background': lightGrey100,
    'profile-btn-icon': black,
    'profile-item-background__hover': cream,
    'profile-text': lightGrey900,
    'search-field-text': lightGrey500,
    'secondary-button__default': white,
    'secondary-button__disabled': white,
    'secondary-button__hover': cream,
    'secondary-button-text__default': black,
    'secondary-button-text__disable': lightGrey500,
    'secondary-button-text__hover': black,
    'slider-border': black,
    'slider-thumb': white,
    'slider-track': lightGrey300,
    'tag-background': pinkish,
    'tag-text': lightGrey900,
    'text-field__placeholder': lightGrey700,
    'text-field__text': black,
    'text-field__underline__active': black,
    'text-field__underline__error': error,
  }
}
