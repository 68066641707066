
import { defineComponent } from 'vue';

import TheSettingsForm from '@/components/TheSettingsForm.vue';
import TheSettingsHeader from '@/components/TheSettingsHeader.vue';

export default defineComponent({
  name: 'SettingsView',
  components: {
    TheSettingsHeader,
    TheSettingsForm,
  }
})
