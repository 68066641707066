import { defineStore } from 'pinia';

import UserApi from '@/backend/UserApi';

export const useCandidateStore = defineStore('candidate', {
  state: () => {
    return {
      settingsFirstname: '',
      settingsLastname: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      dreamJobs: [] as string[],
    };
  },
  actions: {
    async fetchSession(): Promise<any> {
      return UserApi.me().then((response: any) => {
        this.firstname = response.data['First name'];
        this.lastname = response.data['Last name'];
        this.email = response.data['Email'];
        this.dreamJobs = response.data['Dream job'];

        this.settingsFirstname = this.firstname;
        this.settingsLastname = this.lastname;
      });
    },
    async updateCandidate(): Promise<any> {
      return UserApi.update(this.settingsFirstname, this.settingsLastname, this.password).then((response: any) => {
        const candidate = response.data.records[0].fields;
        this.firstname = candidate['First name'];
        this.lastname = candidate['Last name'];
        this.password = '';
      });
    },
    async resetSettings() {
      this.settingsFirstname = this.firstname;
      this.settingsLastname = this.lastname;
      this.password = '';
    },
  },
});
