import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!

  return (_openBlock(), _createBlock(_component_v_checkbox_btn, {
    "v-bind": _ctx.$attrs,
    class: "bsa-checkbox",
    "false-icon": _ctx.falseIcon,
    "true-icon": _ctx.trueIcon
  }, null, 8, ["v-bind", "false-icon", "true-icon"]))
}