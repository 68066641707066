<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.375 11.125H2.625C2.13281 11.125 1.75 10.7422 1.75 10.25V3.25C1.75 2.78516 2.13281 2.375 2.625 2.375H4.375C4.83984 2.375 5.25 1.99219 5.25 1.5C5.25 1.03516 4.83984 0.625 4.375 0.625H2.625C1.14844 0.625 0 1.80078 0 3.25V10.25C0 11.7266 1.14844 12.875 2.625 12.875H4.375C4.83984 12.875 5.25 12.4922 5.25 12C5.25 11.5352 4.83984 11.125 4.375 11.125ZM13.7266 6.14844L10.2266 2.64844C9.89844 2.29297 9.32422 2.29297 8.99609 2.64844C8.64062 2.97656 8.64062 3.55078 8.99609 3.87891L10.9922 5.875H5.25C4.75781 5.875 4.375 6.28516 4.375 6.75C4.375 7.24219 4.75781 7.625 5.25 7.625H10.9922L8.99609 9.64844C8.64062 9.97656 8.64062 10.5508 8.99609 10.8789C9.32422 11.2344 9.89844 11.2344 10.2266 10.8789L13.7266 7.37891C14.082 7.05078 14.082 6.47656 13.7266 6.14844Z"
      fill="#242424"/>
  </svg>
</template>
