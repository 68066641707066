
import { defineComponent } from 'vue';

import TheHeaderProfile from '@/components/TheHeaderProfile.vue'

export default defineComponent({
  name: 'TheSettingsHeader',
  components: {
    TheHeaderProfile
  }
})
