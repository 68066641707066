<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 7.875C12.75 8.29688 12.3984 8.64844 12 8.64844H8.625V12.0234C8.625 12.4219 8.27344 12.75 7.875 12.75C7.45312 12.75 7.125 12.4219 7.125 12.0234V8.64844H3.75C3.32812 8.64844 3 8.29688 3 7.875C3 7.47656 3.32812 7.14844 3.75 7.14844H7.125V3.77344C7.125 3.35156 7.45312 3 7.875 3C8.27344 3 8.625 3.35156 8.625 3.77344V7.14844H12C12.3984 7.125 12.75 7.47656 12.75 7.875Z"
      fill="currentColor"/>
  </svg>
</template>
