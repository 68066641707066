
import { defineComponent } from 'vue';
import { mapActions, mapState, mapWritableState } from 'pinia';

import { useEnumsStore } from '@/store/enums';

import BsaCheckboxGroup from '@/components/BsaCheckboxGroup.vue';
import { useFiltersStore } from '@/store/filters';

export default defineComponent({
  name: 'TheDomainCheckboxGroup',
  components: {
    BsaCheckboxGroup
  },
  computed: {
    ...mapState(useEnumsStore, ['company_tags']),
    ...mapWritableState(useFiltersStore, ['tags']),
  },
  methods: {
    ...mapActions(useFiltersStore, ['fetchStartups'])
  }
})
