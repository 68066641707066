<template>
  <div class="d-inline-block">
    <div class="d-flex align-center autocomplete-item">
      <slot />
      <v-btn
        icon
        size="xsmall"
        variant="plain"
        @click="$emit('remove')">
        <v-icon>$timesIcon</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss">
.autocomplete-item {
  cursor: pointer;
  padding: 12px 8px 12px 16px;
  background: transparent !important;
  color: rgb(var(--v-theme-autocomplete-selected-text));
  border: 2px solid rgb(var(--v-theme-autocomplete-selected-border));
  border-radius: 4px;
  .v-icon {
    color: rgb(var(--v-theme-autocomplete-selected-icon)) !important;
  }
  &:hover {
    color: rgb(var(--v-theme-autocomplete-selected-text__hover));
    background-color: rgb(var(--v-theme-autocomplete-selected-bg__hover)) !important;
    .v-icon {
      color: rgb(var(--v-theme-autocomplete-selected-icon__hover)) !important;
    }
  }
}
</style>
