
import { defineComponent } from 'vue';
import { useTheme } from 'vuetify/lib/framework.mjs';

export default defineComponent({
  name: 'TheBsaLogo',
  computed: {
    logoImage(): any {
      const theme = useTheme()
      const logoImage = theme.global.current.value.dark ? 'bsa-logo-cream.png' : 'bsa-logo-black.png';

      return require(`@/assets/${logoImage}`)
    }
  }
})
