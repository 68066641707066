import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TheHeaderProfile = _resolveComponent("TheHeaderProfile")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { class: "d-flex justify-space-between" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: "/",
            class: "d-flex align-center text-body-m-semibold bsa-router-link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                class: "mr-2",
                color: "homepage-text",
                icon: "$longArrowLeft"
              }),
              _createVNode(_component_v_sheet, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('homepage')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_TheHeaderProfile)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}