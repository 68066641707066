import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  align: "center",
  class: "text-display-s-bold text-card-title"
}
const _hoisted_2 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaButton = _resolveComponent("BsaButton")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
    persistent: ""
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_BsaButton, _mergeProps(props, {
        class: "d-flex d-sm-none",
        "button-type": "bsa-primary",
        icon: "$longArrowRight"
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('card.getInTouch')), 1)
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        rounded: "lg",
        color: "card-background",
        class: "bsa-card px-4 py-4 px-md-6 py-md-6"
      }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('contactStartup', { startup: _ctx.startup })), 1)
        ]),
        text: _withCtx(() => [
          _createVNode(_component_v_divider, { class: "mb-4 mt-4" }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BsaButton, {
              icon: "$longArrowRight",
              "button-type": "bsa-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.dialog = false; _ctx.$emit('confirmContact')})
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('yes')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BsaButton, {
              class: "mt-4",
              icon: "$timesIcon",
              "button-type": "bsa-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('no')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}