
import { defineComponent } from 'vue';

import BsaSearchAutocompleteItem from '@/components/BsaSearchAutocompleteItem.vue';

export default defineComponent({
  name: 'BsaSearchAutocomplete',
  components: {
    BsaSearchAutocompleteItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as () => string[],
      required: true,
    },
    modelValue: {
      type: Array as () => string[],
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data: () => ({
    search: '',
  }),
  methods: {
    removeItem(item: string) {
      const newValue = this.modelValue.filter((element) => element !== item);
      this.$emit('update:modelValue', newValue);
    },
  },
});
