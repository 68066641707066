import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1939c56b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 d-flex align-center justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: _normalizeClass([_ctx.buttonType, "bsa-button"]),
    rounded: "pill"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createVNode(_component_v_icon, { icon: _ctx.icon }, null, 8, ["icon"])
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}