import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsaTextField = _resolveComponent("BsaTextField")!
  const _component_BsaButton = _resolveComponent("BsaButton")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("form", {
    class: "h-100 d-flex align-center justify-center",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.askReset && _ctx.askReset(...args)), ["prevent"]))
  }, [
    _createVNode(_component_v_col, { md: "4" }, {
      default: _withCtx(() => [
        _createVNode(_component_BsaTextField, {
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          label: _ctx.$t('fields.email'),
          type: "email",
          required: "",
          class: "mb-12"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_BsaButton, {
          block: "",
          type: "submit",
          "button-type": "bsa-primary",
          icon: "$longArrowRight"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('password.reset')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}