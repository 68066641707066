import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mb-4 mb-md-12" }
const _hoisted_2 = { class: "text-body-l-semibold text-primary mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheWelcomeFormValidationList = _resolveComponent("TheWelcomeFormValidationList")!
  const _component_BsaTextField = _resolveComponent("BsaTextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_BsaButton = _resolveComponent("BsaButton")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('password.createContains')), 1),
      _createVNode(_component_TheWelcomeFormValidationList, {
        "has-at-least8-characters": _ctx.atLeast8Characters(),
        "has-numbers-and-letters": _ctx.numbersAndLetters(),
        "has-a-special-character": _ctx.aSpecialCharacter()
      }, null, 8, ["has-at-least8-characters", "has-numbers-and-letters", "has-a-special-character"])
    ]),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { md: "7" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "px-0 mb-2 mb-md-4 mb-lg-8" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BsaTextField, {
                      modelValue: _ctx.password,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                      label: _ctx.$t('fields.password'),
                      type: "password"
                    }, null, 8, ["modelValue", "label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "px-0 mb-6 mb-md-8 mb-lg-12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BsaTextField, {
                      modelValue: _ctx.confirmation,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation) = $event)),
                      label: _ctx.$t('fields.confirmation'),
                      type: "password",
                      rules: [_ctx.matchingPasswords]
                    }, null, 8, ["modelValue", "label", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "px-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BsaButton, {
                      block: "",
                      "button-type": "bsa-primary",
                      icon: "$longArrowRight",
                      onClick: _ctx.createPassword
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('password.createButton')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}