<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1562 15.0039C16.4023 15.2773 16.4023 15.6875 16.1562 15.9336C15.8828 16.207 15.4727 16.207 15.2266 15.9336L12 12.6797L8.74609 15.9336C8.47266 16.207 8.0625 16.207 7.81641 15.9336C7.54297 15.6875 7.54297 15.2773 7.81641 15.0039L11.0703 11.75L7.81641 8.49609C7.54297 8.22266 7.54297 7.8125 7.81641 7.56641C8.0625 7.29297 8.47266 7.29297 8.71875 7.56641L12 10.8477L15.2539 7.59375C15.5 7.32031 15.9102 7.32031 16.1562 7.59375C16.4297 7.83984 16.4297 8.25 16.1562 8.52344L12.9023 11.75L16.1562 15.0039Z"
      fill="currentColor"/>
  </svg>
</template>
