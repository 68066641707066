
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BsaTextField',
  props: {
    label: {
      type: String,
      required: true
    },
  }
})
