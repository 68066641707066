<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 8.25C18.125 8.49609 18.0156 8.71484 17.8516 8.87891L10.8516 15.8789C10.6875 16.043 10.4688 16.125 10.25 16.125C10.0039 16.125 9.78516 16.043 9.62109 15.8789L6.12109 12.3789C5.95703 12.2148 5.875 11.9961 5.875 11.75C5.875 11.2578 6.25781 10.875 6.75 10.875C6.96875 10.875 7.1875 10.9844 7.35156 11.1484L10.25 14.0195L16.6211 7.64844C16.7852 7.48438 17.0039 7.375 17.25 7.375C17.7148 7.375 18.125 7.75781 18.125 8.25Z"
      fill="currentColor"/>
  </svg>
</template>
