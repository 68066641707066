
import { defineComponent } from 'vue';

import BsaValidationListItem from './BsaValidationListItem.vue';

export default defineComponent({
  name: 'TheWelcomeFormValidationList',
  components: {
    BsaValidationListItem
  },
  props: {
    hasAtLeast8Characters: {
      type: Boolean,
      required: true
    },
    hasNumbersAndLetters: {
      type: Boolean,
      required: true
    },
    hasASpecialCharacter: {
      type: Boolean,
      required: true
    },
  },
})
