import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheBsaLogo = _resolveComponent("TheBsaLogo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TheWelcomeParagraph = _resolveComponent("TheWelcomeParagraph")!
  const _component_TheWelcomeForm = _resolveComponent("TheWelcomeForm")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "h-screen" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "h-100" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            class: "pt-4 pt-md-12",
            md: "9",
            lg: "7"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pl-2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TheBsaLogo)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, {
                class: "h-100",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_TheWelcomeParagraph, { class: "mb-7 mb-md-13" }),
                      _createVNode(_component_TheWelcomeForm)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}