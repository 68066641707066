
import { defineComponent } from 'vue';
import { mapActions, mapWritableState } from 'pinia';

import { useFiltersStore } from '@/store/filters';

export default defineComponent({
  name: 'TheSearchBar',
  computed: {
    ...mapWritableState(useFiltersStore, ['search']),
    searchBarText(): string {
      if (this.$vuetify.display.mobile)
        return this.$t('searchBar.small');

      return this.$t('searchBar.full');
    }
  },
  methods: {
    ...mapActions(useFiltersStore, ['fetchStartups'])
  }
})
