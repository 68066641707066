
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BsaValidationListItem',
  props: {
    validated: {
      type: Boolean,
      default: false
    }
  }
})
