import axios from 'axios';

const axiosConfig = {
  baseURL: process.env.VUE_APP_API_URL,
};

const customAxios = axios.create(axiosConfig);

export const setAuthHeader = (authorization: string) => {
  customAxios.defaults.headers.common.authorization = authorization;
}

if (localStorage.authorization) {
  setAuthHeader(localStorage.authorization)
}

export default customAxios;
