
import { defineComponent } from 'vue';

import TheBsaLogo from '@/components/TheBsaLogo.vue';
import TheResetPasswordForm from '@/components/TheResetPasswordForm.vue';

export default defineComponent({
  name: 'ResetPasswordView',
  components: {
    TheBsaLogo,
    TheResetPasswordForm,
  }
})
