<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 11.75C11.0156 11.75 10.25 12.543 10.25 13.5C10.25 14.4844 11.0156 15.25 12 15.25C12.957 15.25 13.75 14.4844 13.75 13.5C13.75 12.543 12.957 11.75 12 11.75ZM17.7148 8.30469L15.418 6.00781C15.1992 5.78906 14.8438 5.625 14.5156 5.625H7.625C6.64062 5.625 5.875 6.41797 5.875 7.375V16.125C5.875 17.1094 6.64062 17.875 7.625 17.875H16.375C17.332 17.875 18.125 17.1094 18.125 16.125V9.23438C18.125 8.90625 17.9609 8.55078 17.7148 8.30469ZM9.375 6.9375H13.3125V9.125H9.375V6.9375ZM16.8125 16.125C16.8125 16.3711 16.5938 16.5625 16.375 16.5625H7.625C7.37891 16.5625 7.1875 16.3711 7.1875 16.125V7.375C7.1875 7.15625 7.37891 6.9375 7.625 6.9375H8.0625V9.78125C8.0625 10.1641 8.33594 10.4375 8.71875 10.4375H13.9688C14.3242 10.4375 14.625 10.1641 14.625 9.78125V7.04688L16.7578 9.20703C16.7852 9.23438 16.8125 9.26172 16.8125 9.31641V16.125Z"
      fill="currentColor"/>
  </svg>
</template>
