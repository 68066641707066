import axios from './axios';

export default {
  update: (token: string, password: string) =>
    axios.put('/users/password', {
      user: {
        reset_password_token: token,
        password: password
      }
    }),
  reset: (email: string) => axios.post('/user/password/reset', {
    user: { email: email }
  }),
};
