import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-none d-sm-flex flex-column" }
const _hoisted_2 = { class: "text-body-m text-dashboard-section-title mb-6" }
const _hoisted_3 = { class: "text-body-m text-dashboard-section-title mb-6" }
const _hoisted_4 = { class: "text-body-m text-dashboard-section-title mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDashboardHeader = _resolveComponent("TheDashboardHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TheSearchBar = _resolveComponent("TheSearchBar")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_TheDashboardFiltersPanel = _resolveComponent("TheDashboardFiltersPanel")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_tabs_slider = _resolveComponent("v-tabs-slider")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_BsaCard = _resolveComponent("BsaCard")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pt-4 pt-md-14" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "10",
            "offset-md": "1",
            class: "mb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TheDashboardHeader)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            md: "10",
            "offset-md": "1",
            class: "mb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TheSearchBar)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "3",
            "offset-md": "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panels, {
                id: "expanding-filters",
                class: "d-sm-none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "text-body-m text-dashboard-section-title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('filters')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_TheDashboardFiltersPanel)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('filters')), 1),
                _createVNode(_component_TheDashboardFiltersPanel)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { md: "7" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tabs, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                "align-with-title": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tabs_slider, { color: "yellow" }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (item) => {
                    return (_openBlock(), _createBlock(_component_v_tab, {
                      key: item.value,
                      value: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(item.title)), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_window, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window_item, { value: "startups" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.startupCards.length) + " " + _toDisplayString(_ctx.$t('results')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.startupCards, (item, index) => {
                        return (_openBlock(), _createBlock(_component_BsaCard, {
                          key: index,
                          class: "mb-5",
                          title: item.title,
                          description: item.description,
                          location: item.location,
                          chips: item.chips,
                          "published-at": item.publishedAt,
                          "action-done": item.mailSent,
                          "done-action-text": _ctx.$t('card.mailSent'),
                          "logo-url": item.logoUrl,
                          to: item.website,
                          contact: item.contact,
                          "contact-to": item.contactLinkedin,
                          onCardClick: ($event: any) => (_ctx.contactStartup(item.title))
                        }, null, 8, ["title", "description", "location", "chips", "published-at", "action-done", "done-action-text", "logo-url", "to", "contact", "contact-to", "onCardClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "jobs" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.jobCards.length) + " " + _toDisplayString(_ctx.$t('results')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobCards, (item, index) => {
                        return (_openBlock(), _createBlock(_component_BsaCard, {
                          key: index,
                          class: "mb-5",
                          title: item.title,
                          description: item.description,
                          location: item.location,
                          chips: item.chips,
                          "right-chip": item.rightChip,
                          "published-at": item.publishedAt,
                          "action-done": item.mailSent,
                          "done-action-text": _ctx.$t('card.mailSent'),
                          "logo-url": item.logoUrl,
                          to: item.website,
                          contact: item.contact,
                          "contact-to": item.contactLinkedin,
                          onCardClick: ($event: any) => (_ctx.contactStartup(item.title))
                        }, null, 8, ["title", "description", "location", "chips", "right-chip", "published-at", "action-done", "done-action-text", "logo-url", "to", "contact", "contact-to", "onCardClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}