
import { defineComponent } from 'vue';

import BsaButton from './BsaButton.vue';
import BsaTextField from './BsaTextField.vue';
import TheWelcomeFormValidationList from './TheWelcomeFormValidationList.vue';

import PasswordApi from '@/backend/PasswordApi';

export default defineComponent({
  name: 'TheWelcomeForm',
  components: {
    BsaButton,
    BsaTextField,
    TheWelcomeFormValidationList
  },
  data() {
    return {
      confirmation: '',
      password: ''
    }
  },
  methods: {
    createPassword() {
      const { token } = this.$route.query;
      if (token) {
        PasswordApi.update(token as string, this.password)
          .then(() => this.$router.push('/login'));
      }
    },
    atLeast8Characters() {
      return this.password.length >= 8;
    },
    numbersAndLetters() {
      const atLeastOneNumber = /\d/.test(this.password);
      const atLeastOneLetter = /\w/.test(this.password);
      return atLeastOneNumber && atLeastOneLetter;
    },
    aSpecialCharacter() {
      const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
      return specialChars.test(this.password);
    },
    matchingPasswords() {
      if (this.password !== this.confirmation) {
        return 'Passwords do not match.';
      }
      return true;
    }
  },
})
