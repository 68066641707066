import { defineStore } from 'pinia'

import { setAuthHeader } from '@/backend/axios';

import SessionApi from '@/backend/SessionApi';
import PasswordApi from '@/backend/PasswordApi';

export const useLoginStore = defineStore('login', {
  state: () => {
    return {
      email: '',
      password: '',
    }
  },
  actions: {
    async postLogin(): Promise<any> {
      localStorage.removeItem('authorization');
      setAuthHeader('');
      const response = await SessionApi.signIn(this.email, this.password);

      const authorization = response.headers.authorization;
      localStorage.setItem('authorization', authorization);
      setAuthHeader(authorization);
      this.password = '';

      return response;
    },
    async signOut(): Promise<any> {
      const response = await SessionApi.signOut();

      localStorage.removeItem('authorization');
      setAuthHeader('');

      return response;
    },
    async resetPassword(): Promise<any> {
      const response = await PasswordApi.reset(this.email);

      return response;
    }
  },
})
