import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbar', {
  state: () => {
    return {
      active: false,
      message: '',
    }
  }
});
