
import { defineComponent } from 'vue';

import TheBsaLogo from '@/components/TheBsaLogo.vue';
import TheLoginForm from '@/components/TheLoginForm.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    TheBsaLogo,
    TheLoginForm,
  }
})
